/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import Table from '../../../../components/Table/index';

class EmailTemplateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onClickAddNewEmailTemplate = () => {
    const { handleAddNew } = this.props;
    handleAddNew();
  }

  handleClickEditRow = (idRow) => {
    const { emailTemplateEditing, emailTemplates } = this.props;
    const filteredData = emailTemplates.find(item => item.id === idRow);
    emailTemplateEditing(filteredData);
  }

  handleClickDeleteRow = (idRow) => {
    const { emailTemplateDeleting, emailTemplates } = this.props;
    const filteredData = emailTemplates.find(item => item.id === idRow);
    emailTemplateDeleting(filteredData);
  }


  render() {
    const { emailTemplates } = this.props;
    return (
      <Card>
        <div className="card-form-wrapper">
          <button
            type="button"
            className="card-form__btn card-form__btn--submit"
            color="primary"
            onClick={() => this.onClickAddNewEmailTemplate()}
          >
            Add
          </button>
        </div>
        <Table
          data={emailTemplates}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              sortable: true,
            },
            {
              Header: 'English Subject',
              accessor: 'subject.en',
              sortable: true,
            },
            {
              Header: 'Vietnamese Subject',
              accessor: 'subject.vi',
              sortable: true,
            },
            {
              Header: 'Job Status',
              accessor: 'jobStatus',
              Cell: ({ value }) => {
                // Render giá trị tương ứng với giá trị trong jobStatus
                switch (value) {
                  case 'Processing':
                    return 'Đang xử lý';
                  case 'OlNotSend':
                    return 'Từ chối UV';
                  case 'statusNotSendOLOnlySave':
                    return 'Từ chối UV nhưng lưu dự phòng';
                  case 'notSendIL':
                    return 'Không mời UV phỏng vấn';
                  case 'willSendIL':
                    return 'Sẽ mời UV phỏng vấn';
                  case 'alreadySendIL':
                    return 'Đã mời UV phỏng vấn';
                  case 'candidateDeclinedIL':
                    return 'UV từ chối phỏng vấn';
                  case 'candidateAcceptedIL':
                    return 'UV đồng ý phỏng vấn';
                  case 'OlWillNotSend':
                    return 'Không mời UV thử việc';
                  case 'OlWillSend':
                    return 'Sẽ mời UV thử việc';
                  case 'OlSent':
                    return 'Đã mời UV thử việc';
                  case 'OlAccepted':
                    return 'UV đồng ý thử việc';
                  case 'OlRejected':
                    return 'UV từ chối thử việc';
                  case 'UnsuccessfulProbation':
                    return 'NV thử việc thất bại';
                  case 'SuccessfulProbation':
                    return 'NV thử việc thành công';
                  case 'DeniedProbation':
                    return 'NV từ chối làm việc chính thức';
                  default:
                    return value;
                }
              },
            },
            {
              Header: 'Actions',
              accessor: null,
              Cell: (row) => {
                const { id } = row.original;
                return (
                  <div className="table-actions">
                    <span
                      role="presentation"
                      className="action-edit"
                      onClick={() => this.handleClickEditRow(id)}
                    >
                      Edit
                    </span>
                    <span
                      role="presentation"
                      className="action-delete"
                      onClick={() => this.handleClickDeleteRow(id)}
                    >
                      Delete
                    </span>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
      </Card>
    );
  }
}
EmailTemplateList.propTypes = {
  handleAddNew: PropTypes.func.isRequired,
  emailTemplateEditing: PropTypes.func.isRequired,
  emailTemplates: PropTypes.instanceOf(Object).isRequired,
};

export default EmailTemplateList;
