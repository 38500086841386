/* App config for apis
 */
const ApiConstants = {
  BASE_URL: process.env.BUILD_ENV === 'production'
    ? 'https://devapi.recruitconnect.vn' : 'https://devapi.recruitconnect.vn',
  LOGIN: '/admin/login',
  DEPARTMENT: '/departments',
  FUNCTION: '/functions',
  TEMPLATE: '/master-template',
  EXPERTISE: '/function-expertise',
  DESCRIPTION: '/job-description',
  QUESTION: '/questions',
  EMAILTEMPLATE: '/email-template',
  EMAILTEMPLATESYSTEM: '/email-templatesystem',
  JOBSEEKER: '/admin/candidates',
  RECRUITER: '/admin/recruiters',
  UPLOADFILE: '/file/upload',
  NEWS: '/news',
  NEWS_CATEGORIES: '/news-category',
  NEWS_TAG: '/tag',
  FAQ_CATEGORIES: '/faq-subject',
  FAQ: '/faq',
  HOT_CAREERS: '/admin/hot-careers',
  HOT_CAREERS_GET_BY_ID: '/hot-career',
  HOT_INDUSTRIES: '/hot-industry',
  CONTACT: '/contact',
  GET_GLOBAL_SETTING: '/get-config',
  PATCH_GLOBAL_SETTING: '/admin/config',
  CHART: '/chart',
};

const METHOD = {
  PATCH: 'PATCH',
  POST: 'post',
  GET: 'get',
  DELETE: 'DELETE',
};

export {
  METHOD,
};

export default ApiConstants;
